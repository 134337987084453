import { Component, Prop, Vue } from "vue-property-decorator";
import DfProperty from "@/vue/domain/content/df-property";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfContent from "@/vue/domain/content/df-content";
import DfStore from "@/vue/domain/store/df-store";

@Component
export default class DfCardBannerComponent extends Vue {
  @Prop({ type: Object, default: null })
  banner: DfContent;

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get bannerText(): string {
    return Utils.getPropertyValue(this.banner, Utils.PROPERTY_TEXT, "TEXT");
  }

  get bannerPreviewUrl(): string {
    const property: DfProperty = this.banner.properties ? this.banner.properties.find((property: DfProperty) => property.type === "FILE") || null : null;
    const bannerPreviewUrl: string = property && property.values.length > 0 ? `${Utils.getContextPath(true)}/files/${property.values[0].uniqueId}/${property.values[0].basename}.${property.values[0].extension}` : null;
    return bannerPreviewUrl;
  }

  private onClick() {
    const property: DfProperty = this.banner.properties ? this.banner.properties.find((property: DfProperty) => property.code === Utils.PROPERTY_LINK) || null : null;
    const bannerUrl: string = property && property.values.length > 0 ? property.values[0] : null;
    if (bannerUrl) {
      Utils.isExternalLink(bannerUrl) ? window.open(bannerUrl, "_blank") : this.$router.push({ path: bannerUrl.startsWith("/") ? bannerUrl : `/${bannerUrl}` });
    }
  }
}
